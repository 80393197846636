import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Privacy = () => (
  <Layout>
    <SEO title="מדיניות פרטיות" />
    <div className="row" style={{ fontSize: '0.9em', overflow: 'hidden' }}>
      <div className="col-12">
        <h2>מדיניות פרטיות</h2>
        <br />
        <p>
          <strong>
            <span>א. הקדמה</span>
          </strong>
        </p>
        <ol>
          <li>
            <p>
              <span>
                על השימוש באתר זה www.locallocksmith.co.il או בכל אתר שיחליף
                אותו או יתווסף אליו (&quot;האתר&quot;) תחול מדיניות פרטיות
                כמפורט להלן.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                &nbsp;הפרטיות של המבקרים באתר שלנו חשובה לנו מאוד, ואנחנו
                מחויבים לשמירה עליה. המדיניות הזו מסבירה מה נעשה עם הפרטים
                האישיים שלכם.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                גלישה באתר זה מהווה את הסכמתכם לשימוש שלנו בעוגיות (קבצי
                &quot;cookies&quot;) בהתאם לתנאים של מדיניות זו. הסכמה זו מאפשרת
                לנו להשתמש בעוגיות בכל פעם שאתם מבקרים באתר.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                מדיניות זו מנוסחת לעתים בלשון זכר מטעמי נוחות בלבד, והיא חלה
                באופן זהה עבור כל המינים.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <strong>
            <span>ב. קרדיט</span>
          </strong>
        </p>
        <p>
          <span>
            המסמך הזה נוצר בעזרת תבנית של (SEQ Legal (seqlegal.com ושונתה על ידי
            Website Planet
          </span>
        </p>
        <p>
          <strong>
            <span>ג. איסוף פרטים אישיים</span>
          </strong>
        </p>
        <p>
          <span>
            ייתכן שהסוגים הבאים של פרטים אישיים ייאספו, יאוחסנו, ויעשה בהם
            שימוש:
          </span>
        </p>
        <ol>
          <li>
            <p>
              <span>
                מידע על המחשב שלכם, כולל כתובת ה-IP שלכם, מיקומכם הגאוגרפי (עיר
                בלבד), סוג הדפדפן והגרסה שלו, ומערכת ההפעלה;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                מידע על הביקורים והשימוש שלכם באתר, כולל מקור ההפניה, אורך
                הביקור, צפיות בעמודים, ונתיבי המעבר שלכם באתר;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                מידע, כמו למשל השם וכתובת הדואר האלקטרוני שלכם, שאתם מזינים על
                מנת ליצור קשר איתנו;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>המידע שאתם מזינים בזמן השימוש בשירותים באתר שלנו;</span>
            </p>
          </li>
          <li>
            <p>
              <span>
                מידע שנוצר בזמן השימוש באתר שלנו, כולל מתי, כמה, ובאילו נסיבות
                אתם משתמשים בו;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                מידע שנכלל במסרים שאתם שולחים לנו בדואר האלקטרוני או באמצעות
                האתר שלנו, כולל תוכן המסר והמטא-דאתה שלו;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>כל סוג אחר של פרטים אישיים שאתם שולחים אלינו.</span>
            </p>
          </li>
        </ol>
        <p>
          <span>
            לפני שתחשפו בפנינו את הפרטים האישיים של אדם אחר, עליכם לבקש את הסכמת
            האדם הזה גם עבור השיתוף וגם עבור העיבוד של אותם הפרטים האישיים לפי
            מדיניות זו
          </span>
        </p>
        <p>
          <strong>
            <span>ד. שימוש בפרטים האישיים שלכם</span>
          </strong>
        </p>
        <p>
          <span>
            בפרטים אישיים שנמסרו לנו דרך האתר שלנו ייעשה שימוש למטרות שצוינו
            במדיניות זו, או בעמודים הרלוונטיים שבאתר. ייתכן שנשתמש בפרטים
            האישיים שלכם למטרות הבאות:
          </span>
        </p>
        <ol>
          <li>
            <p>
              <span>ניהול האתר והעסק שלנו;</span>
            </p>
          </li>
          <li>
            <p>
              <span>
                שליחה של הצהרות, חשבונות עסקה, ותזכורות תשלום אליכם, ואיסוף
                תשלומים מכם.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>לשלוח לכם הודעות מסחריות שאינן שיווקיות;</span>
            </p>
          </li>
          <li>
            <p>
              <span>לשלוח לכם התראות בדואר אלקטרוני שביקשתם באופן ספציפי;</span>
            </p>
          </li>
          <li>
            <p>
              <span>
                שליחה של מסרים שיווקיים שקשורים לעסק שלנו והעסקים של חברות צד
                ג&rsquo; שנבחרו בקפידה שלדעתנו עשויות לעניין אתכם, בדואר או,
                במקרים שבהם הסכמתם לכך ספציפית, בדואר האלקטרוני, או באמצעות
                טכנולוגיות דומות (אתם יכולים ליידע אותנו בכל עת אם אתם כבר לא
                מעוניינים במסרים שיווקיים);
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                אספקה של מידע סטטיסטי בנוגע למשתמשים שלנו לצד ג&rsquo; (אבל צד
                ג&rsquo; זה לא יוכל לזהות אף משתמש בודד לפי המידע);
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                טיפול בבירורים ותלונות שהוגשו על ידכם או נוגעות אליכם וקשורות
                לאתר שלנו;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>לשמור על האתר שלנו מאובטח ולמנוע הונאה;</span>
            </p>
          </li>
          <li>
            <p>
              <span>
                אימות של היענות לתנאי השירות המכתיבים את אופן השימוש באתר;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>ושימושים אחרים.</span>
            </p>
          </li>
        </ol>
        <p>
          <span>
            אם תמסרו לנו מידע לפרסום באתר שלנו או שמידע יפורסם על ידיכם (כגון
            חוות דעת), ייתכן שנפרסם את המידע ונשתמש בו בדרכים אחרות בהתאם
            למדיניות זו.
          </span>
        </p>
        <p>
          <span>
            אנחנו לא נספק את הפרטים האישיים שלכם ללא הסכמתכם לכל צד ג&rsquo;
            שהוא, לצורך השיווק הישיר שלו, או של כל צד ג&rsquo; אחר.
          </span>
        </p>
        <p>
          <strong>
            <span>ה. חשיפת פרטים אישיים</span>
          </strong>
        </p>
        <p>
          <span>
            אנחנו עשויים למסור את הפרטים האישיים שלכם לכל אחד מהעובדים, המנהלים,
            המבטחים, היועצים המקצועיים, הסוכנים, הספקים, או קבלני המשנה שלנו,
            במידה סבירה וכנדרש למטרות המצוינות במדיניות זו.
          </span>
        </p>
        <p>
          <span>אנחנו עשויים למסור את הפרטים האישיים שלכם:</span>
        </p>
        <ol>
          <li>
            <p>
              <span>לפי מה שנדרש מאתנו על פי חוק;</span>
            </p>
          </li>
          <li>
            <p>
              <span>בהקשר של כל הליך משפטי קיים או עתידי;</span>
            </p>
          </li>
          <li>
            <p>
              <span>
                על מנת לבסס, להפעיל, או להגן על זכויותינו המשפטיות (כולל אספקה
                של מידע לאחרים למטרות מניעת הונאה והפחתה של סיכון אשראי);
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                לרוכש (או הרוכש הפוטנציאלי) של כל עסק או רכוש שאנחנו רוכשים (או
                שוקלים לרכוש);
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                ולכל אדם שאנחנו מאמינים במידה סבירה שעשוי לפנות לבית דין או לכל
                רשות מוסמכת לצורך מסירה של הפרטים האישיים בהם סביר לדעתנו שאותו
                בית דין או רשות יורה על מסירה של פרטים אישיים אלה.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <span>
            למעט על פי הכתוב במדיניות זו, אנו לא נספק את הפרטים האישיים שלכם לצד
            ג&rsquo;.
          </span>
        </p>
        <p>
          <strong>
            <span>ז. שמירת פרטים אישיים</span>
          </strong>
        </p>
        <ol>
          <li>
            <p>
              <span>
                סעיף ז&rsquo; זה מפרט את תהליך ומדיניות שמירת הנתונים שלנו,
                המתוכננים לעזור להבטיח שאנחנו נענים לחובות המשפטיות שלנו הנוגעות
                לשמירה ולמחיקה של פרטים אישיים.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                פרטים אישיים שאנחנו מעבדים עבור כל מטרה או מטרות, לא יישמרו יותר
                מכמה שנדרש עבור מטרה או מטרות אלה.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                על אף ההוראות האחרות בסעיף ז&rsquo;, אנו נשמור מסמכים (כולל
                מסמכים אלקטרוניים) המכילים נתונים אישיים:
              </span>
            </p>
            <ol>
              <li>
                <p>
                  <span>לפי מה שנדרש מאתנו על פי חוק;</span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    אם לדעתנו המסמכים עשויים להיות רלוונטיים לכל הליך משפטי
                    מתמשך או פוטנציאלי;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    על מנת לבסס, להפעיל, או להגן על זכויותינו המשפטיות (כולל
                    אספקה של מידע לאחרים למטרות מניעת הונאה והפחתה של סיכון
                    אשראי).
                  </span>
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <strong>
            <span>ח. אבטחת הפרטים האישיים שלכם</span>
          </strong>
        </p>
        <ol>
          <li>
            <p>
              <span>
                אנחנו ננקוט משנה זהירות ארגוני וטכני סביר על מנת למנוע את האבדן,
                השימוש לרעה, או השינוי של הפרטים האישיים שלכם.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                אנחנו נאחסן את כל הפרטים האישיים שאתם מספקים בשרתים מאובטחים
                (המוגנים בסיסמא ובחומת אש).
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                אתם מכירים בכך שהשידור של מידע על פני האינטרנט אינו מאובטח מעצם
                היותו, ואנחנו לא יכולים להבטיח שנתונים שנשלחים דרך האינטרנט יהיו
                מאובטחים.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <strong>
            <span>ט. תיקונים</span>
          </strong>
        </p>
        <p>
          <span>
            אנחנו עשויים לעדכן מדיניות זו מעת לעת באמצעות פרסום של גרסה חדשה
            באתר שלנו. עליכם לבדוק את העמוד הזה מדי פעם על מנת לוודא שאתם מבינים
            כל שינוי שנעשה במדיניות זו.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>
            <span>י. הזכויות שלכם</span>
          </strong>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>
            <span>יא. אתרים צד ג&rsquo;</span>
          </strong>
        </p>
        <p>
          <span>
            האתר שלנו כולל קישורים חיצוניים, ופרטים על, אתרים צד ג&rsquo;. אין
            לנו שליטה על, ואיננו אחראים על, מדיניות הפרטיות והנהלים של כל צד
            ג&rsquo;.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>
            <span>יב. עדכון מידע</span>
          </strong>
        </p>
        <p>
          <span>
            אנא הודיעו לנו אם יש צורך לתקן או לעדכן את הפרטים האישיים שאנו
            מחזיקים עליכם.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>
            <span>יג. עוגיות</span>
          </strong>
        </p>
        <p>
          <span>
            האתר שלנו משתמש בעוגיות (cookies). עוגייה היא קובץ המכיל מזהה
            (מחרוזת של אותיות ומספרים) שנשלח על ידי שרת אינטרנט לדפדפן אינטרנט
            ומאוחסן בדפדפן. אז, המזהה נשלח בחזרה לשרת בכל פעם שהדפדפן מבקש מהשרת
            להציג דף אינטרנט. עוגיות יכולות להיות או עוגיות &ldquo;עיקשות&rdquo;
            או עוגיות &ldquo;שיח&rdquo; (session): עוגייה עיקשת תאוחסן בדפדפן
            שלכם ותישאר בתוקף עד תאריך התפוגה שנקבע לה, אלא אם תימחק על ידי
            המשתמש לפני תאריך התפוגה; עוגיית שיח, מאידך, תפוג בסוף זמן הגלישה
            הנוכחי שלכם, כשתסגרו את הדפדפן. בדרך כלל עוגיות לא כוללות מידע שמזהה
            משתמשים אישית, אבל פרטים אישיים שאנחנו מאחסנים הנוגעים עליכם יכולים
            להיות מקושרים למידע המאוחסן והמתקבל מתוך עוגיות.
          </span>
        </p>
        <ol>
          <li>
            <p>
              <span>
                השמות של העוגיות בהן אנחנו משתמשים באתר שלנו, והמטרות לשמן הן
                בשימוש, מפורטות מטה:
              </span>
            </p>
            <ol>
              <li>
                <p>
                  <span>
                    אנו משתמשים בשירותי Google Analytics ו-Google Ads באתר שלנו
                    כדי לנתח את השימוש באתר, לשפר את נוחות השימוש באתר ולאפשר
                    לנו להבין טוב יותר אילו שירותים לקוחות פוטנציאלים מחפשים.
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    אנו משתמשים ב-Hotjar על מנת להבין טוב יותר את צרכי המשתמשים
                    שלנו וכדי לייעל את השירות והחוויה הזו. Hotjar הוא שירות
                    טכנולוגי שעוזר לנו להבין טוב יותר את חווית המשתמשים שלנו
                    (למשל כמה זמן הם מבלים באילו דפים, על אילו קישורים הם בוחרים
                    ללחוץ, מה המשתמשים אוהבים ומה לא אוהבים וכו&apos;) וזה מאפשר
                    לנו לבנות ולשמור על השירות שלנו עם משוב משתמשים. Hotjar
                    משתמשת בעוגיות ובטכנולוגיות אחרות כדי לאסוף נתונים על
                    התנהגות המשתמשים שלנו ועל המכשירים שלהם. זה כולל כתובת IP של
                    מכשיר (עובדה במהלך הסשן שלך ומאוחסנת בצורה לא מזוהה), גודל
                    מסך המכשיר, סוג המכשיר (מזהי מכשיר ייחודיים), מידע דפדפן,
                    מיקום גיאוגרפי (מדינה בלבד) והשפה המועדפת המשמשת להצגת אתר
                    האינטרנט שלנו. Hotjar מאחסנת את המידע הזה בשמנו בפרופיל
                    משתמש בדוי. על Hotjar חל איסור חוזי למכור כל אחד מהנתונים
                    שנאספו בשמנו.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <span>
                רוב הדפדפנים מאפשרים לכם לסרב לשימוש בעוגיות&mdash;למשל:
              </span>
            </p>
            <ol>
              <li>
                <p>
                  <span>
                    בפיירפוקס (גרסה 24) אתם יכולים לחסום את כל העוגיות באמצעות
                    לחיצה על &ldquo;כלים,&rdquo; &ldquo;אפשרויות,&rdquo;
                    &ldquo;פרטיות,&rdquo; ובחירה של &ldquo;השתמש בהגדרות מותאמות
                    אישית עבור היסטוריה&rdquo; מתוך התפריט הנפתח, ואז ביטול
                    הזימון של &ldquo;קבל עוגיות מאתרים&rdquo;;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    ובכרום (גרסה 29), אתם יכולים לחסום את כל העוגיות באמצעות
                    כניסה לתפריט &ldquo;התאמה אישית ובקרה,&rdquo; ואז ללחוץ על
                    &ldquo;הגדרות,&rdquo; &ldquo;הצג הגדרות מתקדמות,&rdquo;
                    ו-&ldquo;הגדרות תוכן,&rdquo; ואז לבחור באפשרות &ldquo;חסום
                    אתרים מהגדרה של נתונים&rdquo; תחת הכותרת
                    &ldquo;עוגיות.&rdquo;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  לספארי, אדג' או כל דפדפן אחר, נא לפנות לדוקומנטציה הרלוונטית.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <span>
            לחסימה של כל העוגיות תהיה השפעה שלילית על נוחות השימוש של אתרים רבים
          </span>
        </p>
        <ol start="3">
          <li>
            <p>
              <span>
                אתם יכולים למחוק עוגיות שכבר מאוחסנות במחשב שלכם&mdash;למשל:
              </span>
            </p>
            <ol>
              <li>
                <p>
                  <span>
                    בפיירפוקס (גרסה 24), אתם יכולים למחוק עוגיות באמצעות לחיצה
                    על &ldquo;כלים,&rdquo; &ldquo;אפשרויות,&rdquo;
                    ו-&ldquo;פרטיות&rdquo;, ואז לבחור &ldquo;השתמש בהגדרות
                    מותאמות אישית עבור היסטוריה&rdquo;, וללחוץ על &ldquo;הצג
                    עוגיות,&rdquo; ואז &ldquo;הסר את כל העוגיות&rdquo;;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    ובכרום (גרסה 29), אתם יכולים למחוק את כל העוגיות באמצעות
                    כניסה לתפריט &ldquo;התאמה אישית ובקרה,&rdquo; ואז ללחוץ על
                    &ldquo;הגדרות,&rdquo; &ldquo;הצג הגדרות מתקדמות,&rdquo;
                    ו-&ldquo;נקה נתוני גלישה,&rdquo; ואז לבחור באפשרות
                    &ldquo;מחק עוגיות ונתונים אחרים של אתרים ותוספים,&rdquo;
                    ולבסוף ללחוץ &ldquo;נקה נתוני גלישה.&rdquo;
                  </span>
                </p>
              </li>
              <li>
                <p>
                  לספארי, אדג' או כל דפדפן אחר, נא לפנות לדוקומנטציה הרלוונטית.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <span>
            למחיקה של עוגיות תהיה השפעה שלילית על נוחות השימוש של אתרים
            רבים.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
      </div>
    </div>
  </Layout>
)

export default Privacy
